











import {Component, Vue} from "vue-property-decorator";
import PaymentService from "@/services/PaymentService";
import CompletePaymentRequest from "@/dto/payment/CompletePaymentRequest";
import CompletePaymentResponse from "@/dto/payment/CompletePaymentResponse";
import RouteNames from "@/router/RouteNames";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";

@Component
export default class Payment extends Vue {

    private message = "";

    private successful = true;

    private loading = true;

    mounted(){
        if(!this.$route.query.code){
            this.loading = false;
            this.message = this.$t("error.invalid_payment_code") as string;
            this.successful = false;
            return;
        }
        PaymentService.completePayment(new CompletePaymentRequest(this.$route.query.code as string)).then(
            response => {
                this.loading = false;
                if(response.data.sourceType === 'PERSONAL_TAXATION'){
                    this.goToPersonalQuestionnaire(response.data);
                }else if(response.data.sourceType === 'BUSINESS_TAXATION'){
                    this.goToBusinessQuestionnaire(response.data);
                }
            },
            error => {
                this.loading = false;
                this.successful = false;
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
            }
        );
    }

    goToBusinessQuestionnaire(response: CompletePaymentResponse) {
        this.$router.push({name: RouteNames.TAXATION_BUSINESS_QUESTIONNAIRE, params: {id: `${response.sourceId}`, userId: `${response.userId}`}})
    }

    goToPersonalQuestionnaire(response: CompletePaymentResponse) {
        this.$router.push({name: RouteNames.TAXATION_INDIVIDUAL_QUESTIONNAIRE, params: {userId: `${response.userId}`, id: `${response.sourceId}`}})
    }

}
