import axios from 'axios';
import {
    TEMP_AUTH_COMPLETE_PAYMENT_ENDPOINT
} from '@/constants/endpoints';
import CompletePaymentRequest from "@/dto/payment/CompletePaymentRequest";
import CompletePaymentResponse from "@/dto/payment/CompletePaymentResponse";

class PaymentService {

    completePayment(request: CompletePaymentRequest) {
        return axios.post<CompletePaymentResponse>(TEMP_AUTH_COMPLETE_PAYMENT_ENDPOINT, request);
    }

}

export default new PaymentService();